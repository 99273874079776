import suitImg from 'assets/images/servsuite.svg';

import { media } from 'atoms/breakpoints/breakpoints';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Image from 'quarks/Image';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import Modal from 'molecules/Modal/Modal';

import ComponentButton from 'components/Button/ComponentButton';

import type { FC } from 'react';

interface PopupProps extends BasicProps {
  open: boolean;
  onClose: () => void;
}

const Popup: FC<PopupProps> = ({ open, onClose, ...props }) => (
  <Modal open={open} onClose={onClose} width="90%" maxWidth="730px" marginX="auto">
    <Container position="relative" marginY={32} borderRadius="8px" backgroundColor="gray-50" {...props}>
      <Flex flexDirection="column" paddingAll={24} gap="32px" width="100%" md={{ paddingAll: 40 }}>
        <Flex
          width="100%"
          flexDirection="column"
          gap="32px"
          borderRadius="4px"
          justifyContent="center"
          alignItems="center"
          paddingTop={24}
        >
          <Image image={{ file: { url: suitImg } }} alt="Logo" width="100%" maxWidth="260px" />
          <Text textStyle="textSm" textColor="gray-700" textAlign="center" md={{ textStyle: 'textMd' }}>
            As part of ServiceTitan’s acquisition of FieldRoutes, ServSuite® is now a part of FieldRoutes. This was done
            in a joint effort to ensure we are providing you with best-in-class software to grow your business and
            achieve your goals.
          </Text>
          <Text textStyle="textSm" textColor="gray-700" textAlign="center" md={{ textStyle: 'textMd' }}>
            There is no change to your software or service.
          </Text>
        </Flex>
        <Flex
          width="100%"
          flexDirection="column"
          gap="8px"
          justifyContent="space-between"
          sm={{ flexDirection: 'row' }}
          css={`
            && .MuiButton-root {
              max-width: 100%;
              width: 100%;
              ${media.sm} {
                max-width: calc(50% - 8px);
              }
            }
          `}
        >
          <ComponentButton link="https://live.theservicepro.net" buttonSize="lg" label="Log In To ServSuite" />
          <ComponentButton link="/free-demo-fieldroutes" label="Demo FieldRoutes" buttonColor="Navy White" />
        </Flex>
      </Flex>
    </Container>
  </Modal>
);

export default Popup;
