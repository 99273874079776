import { PageProps, graphql } from 'gatsby';
import Layout from 'layouts/Layout';
import LayoutSection from 'layouts/LayoutSection';
import { FC, useState } from 'react';
import { useLocation } from 'react-use';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Popup from 'components/PagePopup/Popup';
import ServicePopup from 'components/PagePopup/ServicePopup';

import type { ContentfulTemplatePage } from 'graphqlTypes';

interface PageDataType {
  pageData: ContentfulTemplatePage;
}

const Page: FC<PageProps<PageDataType>> = ({
  data: {
    pageData: { seo, header, footer, sections },
  },
  location,
}) => {
  if (!seo) {
    throw new Error(`Page at ${location.pathname} is missing SEO`);
  }
  const { href } = useLocation();
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Layout slug={location.pathname} header={header} footer={footer} seo={seo}>
      {sections &&
        sections.map(
          section =>
            section && (
              <LayoutSection
                key={section.id}
                pageType={seo.pageType}
                displayBreadcrumbs={seo?.displayBreadcrumbs}
                {...section}
              />
            ),
        )}
      {location.pathname === '/' &&
        (href?.includes('redirect=lobster-marketing') || href?.includes('redirect=pestroutes')) && (
          <Popup open={isOpen} onClose={() => setIsOpen(false)} />
        )}
      {location.pathname.includes('servsuite-by-fieldroutes') && href?.includes('redirect=servicepro') && (
        <ServicePopup open={isOpen} onClose={() => setIsOpen(false)} />
      )}
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query templatePageQuery($slug: String!) {
    pageData: contentfulTemplatePage(slug: { eq: $slug }) {
      ...contentfulTemplatePage
    }
  }
`;
