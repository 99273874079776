import lobsterImg from 'assets/images/cell-lobster.svg';
import pestImg from 'assets/images/pestroutes.svg';
import logoImg from 'assets/images/popup-logo.svg';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Image from 'quarks/Image';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import Modal from 'molecules/Modal/Modal';

import ComponentButton from 'components/Button/ComponentButton';

import type { FC } from 'react';

interface PopupProps extends BasicProps {
  open: boolean;
  onClose: () => void;
}

const Popup: FC<PopupProps> = ({ open, onClose, ...props }) => (
  <Modal open={open} onClose={onClose} width="90%" maxWidth="730px" marginX="auto">
    <Container position="relative" marginY={32} borderRadius="8px" backgroundColor="gray-50" {...props}>
      <Flex flexDirection="column" paddingAll={24} gap="32px" width="100%" md={{ paddingAll: 40 }}>
        <Flex width="100%" flexDirection="column" gap="8px" justifyContent="center" alignItems="center">
          <Text textStyle="textMd" textColor="navy-900" lg={{ textStyle: 'textLg' }}>
            Welcome to
          </Text>
          <Image image={{ file: { url: logoImg } }} alt="Logo" width="180px" />
        </Flex>
        <Flex
          width="100%"
          flexDirection="column"
          gap="32px"
          borderRadius="4px"
          backgroundColor="common-white"
          paddingAll={24}
        >
          <Flex
            flexDirection="column"
            alignItems="center"
            width="100%"
            gap="32px"
            justifyContent="center"
            md={{ flexDirection: 'row', gap: '48px' }}
          >
            <Image image={{ file: { url: pestImg } }} alt="Logo" width="180px" />
            <Image image={{ file: { url: lobsterImg } }} alt="Logo" width="180px" />
          </Flex>
          <Text textStyle="textSm" textColor="gray-700" textAlign="center" md={{ textStyle: 'textMd' }}>
            PestRoutes and Lobster Marketing are FieldRoutes, a ServiceTitan company. We’ve been sister companies for
            years and are now operating as one brand name. We continue to provide industry-leading field service
            software, sales, and marketing solutions backed by top-notch customer service and support.
          </Text>
        </Flex>
        <Flex
          width="100%"
          onClick={onClose}
          css={`
            && .MuiButtonBase-root {
              max-width: 100%;
              width: 100%;
            }
          `}
        >
          <ComponentButton label="Continue To Site" />
        </Flex>
      </Flex>
    </Container>
  </Modal>
);

export default Popup;
